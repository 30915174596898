import React from 'react';
import { clsx } from 'clsx';
import { IconRemove } from 'icons';
import RemainingChars from './RemainingChars';

interface Theme {
  textAlign: 'left' | 'center';
  rounded: boolean;
  inactiveBgColor: 'grey' | 'white';
}

const defaultTheme: Theme = {
  textAlign: 'left',
  rounded: false,
  inactiveBgColor: 'white',
};

interface Props {
  value?: React.ComponentProps<'input'>['value'];
  errors?: string | string[] | null;
  isInvalid?: boolean;
  prefix?: string;
  disabled?: boolean;
  isFocused?: boolean;
  children?: React.ReactNode;
  rightIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  leftIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  rightIconClassNames?: string;
  leftIconClassNames?: string;
  onClickIcon: () => void;
  onClear?: () => void;
  multiline?: boolean;
  maxLength?: number;
  isPassword?: boolean;
  theme?: Partial<Theme>;
}

export default function InputContainer(props: Props) {
  const {
    value,
    errors,
    isFocused,
    prefix,
    disabled,
    multiline,
    children,
    rightIcon,
    leftIcon,
    rightIconClassNames,
    leftIconClassNames,
    onClickIcon,
    maxLength,
    onClear,
  } = props;

  const theme = { ...defaultTheme, ...props.theme };

  const handleFocus: React.DOMAttributes<HTMLDivElement>['onClick'] = (e) => {
    const el = e.currentTarget;
    const input = el.querySelector('input,textarea') as HTMLInputElement;
    input?.focus();
  };

  const isInvalid = props.isInvalid || !!errors?.length;

  const borderColorClassName = isInvalid
    ? 'border-error'
    : isFocused
    ? 'border-brightBlue'
    : !value && theme.inactiveBgColor === 'grey'
    ? 'border-grey8'
    : 'border-grey6';

  const bgColorClassName = isInvalid
    ? 'bg-errorBg'
    : disabled
    ? 'bg-grey6'
    : theme.inactiveBgColor === 'grey' && !value && !isFocused
    ? 'bg-grey8'
    : 'bg-white';

  return (
    <div
      onClick={handleFocus}
      className={clsx(
        'flex w-full py-1 border border-solid',
        {
          'pl-1.5 pr-1': theme.textAlign === 'left',
          'px-1': theme.textAlign === 'center',
          'h-5 items-center': !multiline,
          'items-start': multiline,
          'ring ring-opacity-20 ring-brightBlue': isFocused,
        },
        theme.rounded ? 'rounded-full' : 'rounded-lg',
        borderColorClassName,
        bgColorClassName
      )}
    >
      {!!leftIcon &&
        React.createElement(leftIcon, {
          className: clsx(
            'block flex-shrink-0 w-3 h-3 text-dark mr-1',
            {
              'mt-0.5': multiline,
            },
            leftIconClassNames
          ),
          role: 'button',
          onClick: onClickIcon,
        })}
      {!!prefix && (
        <span className="text-light bump-down-1 whitespace-nowrap">
          {prefix}
        </span>
      )}

      {children}

      {!!onClear ? (
        <button
          className={clsx('flex-shrink-0 w-2 h-2 ml-1 text-grey5', {
            'mt-0.5': multiline,
            hidden: !value,
          })}
          onClick={onClear}
          type="button"
        >
          <IconRemove className="block w-full h-full" />
        </button>
      ) : maxLength ? (
        <RemainingChars maxLength={maxLength} value={value} />
      ) : !!rightIcon ? (
        React.createElement(rightIcon, {
          className: clsx(
            'flex-shrink-0 block w-3 h-3 text-dark ml-1',
            {
              'mt-0.5': multiline,
            },
            rightIconClassNames
          ),
          role: 'button',
          type: 'button',
          onClick: onClickIcon,
        })
      ) : null}
    </div>
  );
}
