import { Oauth1Provider, Oauth2Provider, OAUTH_VERSIONS } from './types';
import * as config from './config';
import { OAUTH_REDIRECT_URL } from '../../api/shared';

export const facebook: Oauth2Provider = {
  name: 'facebook',
  authorizationEndpoint: 'https://www.facebook.com/v20.0/dialog/oauth',
  queryParams: [
    ['client_id', config.facebookClientId],
    ['redirect_uri', `${OAUTH_REDIRECT_URL}/publisher/authorize/facebook`],
    ['response_type', 'code'],
  ],
  scope: [
    'business_management',
    'pages_manage_metadata',
    'pages_read_engagement',
    'pages_read_user_content',
    'pages_manage_posts',
    'read_insights',
  ],
  moderationScope: ['pages_manage_engagement'],
  scopeDelimiter: ',',
  oauthType: OAUTH_VERSIONS.OAUTH2,
};

export const instagram_basic: Oauth2Provider = {
  name: 'instagram_basic',
  authorizationEndpoint: 'https://api.instagram.com/oauth/authorize',
  queryParams: [
    ['client_id', config.instagramBasicClientId],
    [
      'redirect_uri',
      `${OAUTH_REDIRECT_URL}/publisher/authorize/instagram_basic`,
    ],
    ['response_type', 'code'],
  ],
  scope: ['user_profile', 'user_media'],
  moderationScope: [],
  scopeDelimiter: ',',
  oauthType: OAUTH_VERSIONS.OAUTH2,
};

export const instagram_graph: Oauth2Provider = {
  name: 'instagram_graph',
  authorizationEndpoint: 'https://www.facebook.com/v20.0/dialog/oauth',
  queryParams: [
    ['client_id', config.facebookClientId],
    ['redirect_uri', `${OAUTH_REDIRECT_URL}/publisher/authorize/facebook`],
    ['response_type', 'code'],
  ],
  scope: [
    'business_management',
    'instagram_basic',
    'instagram_manage_insights',
    'pages_read_engagement',
    'pages_manage_metadata',
    'instagram_content_publish',
  ],
  moderationScope: ['instagram_manage_comments'],
  scopeDelimiter: ',',
  oauthType: OAUTH_VERSIONS.OAUTH2,
};

export const linkedin: Oauth2Provider = {
  name: 'linkedin',
  authorizationEndpoint: 'https://www.linkedin.com/uas/oauth2/authorization',
  queryParams: [
    ['client_id', config.linkedinClientId],
    ['redirect_uri', `${OAUTH_REDIRECT_URL}/publisher/authorize/linkedin`],
    ['response_type', 'code'],
  ],
  scope: ['r_liteprofile', 'w_member_social', 'rw_ads'],
  moderationScope: [],
  // url-encoded space character
  scopeDelimiter: '%20',
  oauthType: OAUTH_VERSIONS.OAUTH2,
};

export const twitter: Oauth1Provider = {
  name: 'twitter',
  authorizationEndpoint: 'https://api.twitter.com/oauth/authenticate',
  oauthType: OAUTH_VERSIONS.OAUTH1,
};
