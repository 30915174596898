import React, { useState, useRef, useEffect } from 'react';

interface Props {
  src?: string;
  name?: string;
  theme?: 'dark' | 'white';
}

export default function PublisherAvatar({ src, name, theme }: Props) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [isImageMissing, setIsImageMissing] = useState(!src);
  const handleMissingImage = () => {
    setIsImageMissing(true);
  };

  useEffect(() => {
    if (!wrapperRef.current) return;
    setWidth(wrapperRef.current.clientWidth);
  }, []);

  const initials = name
    ?.split(' ')
    .slice(0, 2)
    .map((s) => s.replace(/^[^a-zA-z\\?]+/, '').substring(0, 1))
    .join('');

  const classNames =
    theme === 'dark'
      ? 'bg-darkGreyBg'
      : theme === 'white'
      ? 'bg-white'
      : 'bg-greyBg';

  return (
    <div
      className={`rounded-full w-full h-full ${classNames}`}
      ref={wrapperRef}
    >
      {isImageMissing ? (
        <div
          className="flex items-center justify-center text-light w-full h-full uppercase"
          style={{ fontSize: `${Math.round(width * 0.45)}px` }}
        >
          <span className="bump-up-1">{initials}</span>
        </div>
      ) : (
        <img
          src={src}
          onError={handleMissingImage}
          className="rounded-full w-full h-full object-cover"
          alt={name}
        />
      )}
    </div>
  );
}
