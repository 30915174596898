import { io, Socket } from 'socket.io-client';
import uuid from 'uuid4';
import routes from 'helpers/routes';
import { OtherAction } from './global';
import { updatedPost } from './posts';
import { SOCKET_SERVER_URL } from 'api/shared';
import {
  creatingThumbnails,
  fileReady,
  processingError,
  transcodingVideo,
} from './fileUploads';
import { updateAttachment } from './attachments';
import FileProcessingEvent from 'types/FileProcessingEvent';
import { push } from 'connected-react-router';
import { AppDispatch } from 'redux/store';
import { downloadZip } from './batchDownloads';

export enum TypeKeys {
  CONNECTED = 'publisher/socket/CONNECTED',
  DISCONNECTED = 'publisher/socket/DISCONNECTED',
}

export interface ConnectedAction {
  type: TypeKeys.CONNECTED;
  payload: { id: string };
}

export interface DisconnectedAction {
  type: TypeKeys.DISCONNECTED;
}

type ActionTypes = ConnectedAction | DisconnectedAction | OtherAction;

// Reducer
export enum SocketStatus {
  NOT_CONNECTED = 'NOT_CONNECTED',
  CONNECTED = 'CONNECTED',
}

export interface State {
  id?: string;
  status: SocketStatus;
}

const initialState: State = { status: SocketStatus.NOT_CONNECTED };

export default (state = initialState, action: ActionTypes) => {
  switch (action.type) {
    case TypeKeys.CONNECTED:
      return { id: action.payload.id, status: SocketStatus.CONNECTED };
    case TypeKeys.DISCONNECTED:
      return { status: SocketStatus.NOT_CONNECTED };
    default:
      return state;
  }
};

// Action Creators
const connected = (id: string) => ({
  type: TypeKeys.CONNECTED,
  payload: { id },
});

const disconnected = () => ({ type: TypeKeys.DISCONNECTED });

export const connect = () => async (dispatch: AppDispatch) => {
  const socket = io(SOCKET_SERVER_URL);
  const socketId = uuid();

  socket.on('connect', () => {
    socket.emit('authenticate', { uuid: socketId });
    dispatch(connected(socketId));
  });

  socket.on('disconnect', () => {
    dispatch(disconnected());
  });

  socket.on('postContentEvent', ({ requestKey, ...post }: any) => {
    dispatch(updatedPost(post, requestKey));
  });

  socket.on('fileProcessingEvent', (event: FileProcessingEvent) => {
    const { uploadId, message, attachment } = event;
    switch (message) {
      case 'processingPhoto':
      case 'processingVideo':
        dispatch(creatingThumbnails(uploadId));
        break;
      case 'photoReady':
      case 'videoReady':
        dispatch(updateAttachment({ ...attachment, status: 'processed' }));
        dispatch(fileReady(uploadId, attachment));
        break;
      case 'transcodingVideo':
        dispatch(updateAttachment(attachment));
        dispatch(transcodingVideo(uploadId));
        break;
      case 'jobFailed':
        const error = event.error;
        dispatch(processingError(uploadId, error));
        break;
    }
  });

  socket.on('suggestionsAddedToInbox', () => {
    dispatch(push(routes.feeds.inbox));
  });

  socket.on('csvFileEvent', (data: { url: string; uuid?: string }) => {
    dispatch(downloadZip(data));
  });

  return socket;
};

export const disconnect = (socket: Socket) => {
  socket.close();
  return disconnected();
};
