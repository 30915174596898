import React, { forwardRef } from 'react';
import Attachment from 'types/Attachment';
import SaveMediaFileDetails from './SaveMediaFileDetails';
import { useIntl } from 'hooks';
import useMediaDownload from 'hooks/useMediaDownload';
import AttachmentAlbum from 'types/AttachmentAlbum';

interface Props {
  attachment: Attachment | AttachmentAlbum;
  isAttachmentFromAlbum: boolean;
  onClose: () => void;
  publisherRequest?: { requestKey: string };
}

const SaveMediaTooltip = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    publisherRequest,
    attachment,
    isAttachmentFromAlbum,
    onClose,
  } = props;

  const { t } = useIntl();

  const downloadButtonClassNames =
    'block w-full p-2 group transition-fast hover:bg-greyBg first:rounded-t last:rounded-b';

  const { handleDownload } = useMediaDownload({
    onSuccess: onClose,
  });

  const handleDownloadFileFromUrl = (url: string, fileName: string) => {
    let element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', fileName);
    document.body.appendChild(element);
    element.style.display = '';
    element.click();

    document.body.removeChild(element);
    onClose();
  };

  const handleClickDownload = (
    url: string,
    fileName: string,
    isAttachmentFromAlbum = false,
    publisherRequest?: { requestKey: string },
    size?: string
  ) => {
    if (publisherRequest) {
      handleDownload(publisherRequest, undefined, size);
    } else if (isAttachmentFromAlbum) {
      handleDownload(undefined, attachment.id, size);
    } else {
      handleDownloadFileFromUrl(url, fileName);
    }
  };

  const isVideo = attachment.type === 'Video';
  const height = attachment.dimensions.original[1];
  // TODO: We are generating a lower quality 720p rendition for all videos so
  // we can send that over iMessage. But we don't need to show them to the user
  // unless the rendition has a smaller height dimension than the original
  // After we ship the iOS app, we can delete 720p renditions for 720p videos
  // and remove this filter
  const renditions = attachment.renditions?.filter((r) => r.height < height);

  const mediaFileName =
    attachment.mediaFilename ||
    `${attachment.mediaContentType}-${attachment.id}`;

  return (
    <div className="p-0.5">
      {!isVideo ? (
        <button
          onClick={() =>
            handleClickDownload(
              attachment.mediaUrl,
              mediaFileName,
              isAttachmentFromAlbum,
              publisherRequest,
              'large'
            )
          }
          className={downloadButtonClassNames}
        >
          <SaveMediaFileDetails
            dimensions={attachment.dimensions.large}
            title={t('SaveMedia__Optimized')}
          />
        </button>
      ) : (
        <>
          {renditions?.map((rendition) => (
            <button
              key={rendition.id}
              onClick={() =>
                handleClickDownload(
                  attachment.mediaUrl,
                  mediaFileName,
                  isAttachmentFromAlbum,
                  publisherRequest,
                  rendition.name
                )
              }
              className={downloadButtonClassNames}
            >
              <SaveMediaFileDetails
                dimensions={[rendition.width, rendition.height]}
                title={rendition.name}
              />
            </button>
          ))}
        </>
      )}

      <button
        onClick={() =>
          handleClickDownload(
            attachment.mediaUrl,
            mediaFileName,
            isAttachmentFromAlbum,
            publisherRequest
          )
        }
        className={downloadButtonClassNames}
      >
        <SaveMediaFileDetails
          dimensions={attachment.dimensions.original}
          title={t('SaveMedia__Original')}
          fileSize={attachment.mediaSize}
        />
      </button>
    </div>
  );
});

export default SaveMediaTooltip;
