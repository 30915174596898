import PlatformId from '../../types/PlatformId';

export enum OAUTH_VERSIONS {
  OAUTH1 = '1.0',
  OAUTH2 = '2.0',
}

export interface Oauth1Provider {
  oauthType: string;
  name: PlatformId;
  authorizationEndpoint: string;
}

export interface Oauth2Provider extends Oauth1Provider {
  scope: string[];
  moderationScope: string[];
  scopeDelimiter: string;
  /**
   * query params for the authorization end point
   */
  queryParams: [string, string][];
}

export interface AuthenticationRequestOptions {
  platformId: PlatformId;
  /**
   * A publisherId or brandName must be supplied, use the publisherId
   * when it is known (eg: when logging into a publisherRequest)
   */
  publisherId?: number;
  /**
   * suggesterId must be supplied when the publisherId is not known
   */
  suggesterId?: number;
  /**
   * various reasons to auth, default in the auth api helper is 'suggestion'
   * which is when the user is linking anywhere in within the draft editor
   */
  authType?:
    | 'on_boarding'
    | 'inbox'
    | 'settings'
    | 'suggestion_login'
    | 'suggestion'
    | 'failed_post'
    | 'app_install';

  // The url to this app that the api server should redirect to when the user
  // successfully authenticates w/a social network - server uses the referrer
  // url if not specified
  successRedirect?: string;

  // The url to this app that the api server should redirect to when an error
  // occurs while authorizing w/a social network
  errorRedirect?: string;

  // Optional params to pass to the oauth provider's login dialog, the inner array contains the key/value for
  // each param, eg: [ ['error', 'disk_full'], ['severity', 'high'] ] will turn into error=disk_full&severity=high
  providerParams?: [string, string][];

  // Set to true if the user is onboarding and this is the first account being
  // connected
  onboarding?: boolean;

  // When used for Slack app installs
  appType?: 'publisher';

  // Pass the invite code the user used to automatically put them into groups
  inviteCode?: string;

  // Add the extra scopes for content moderation
  useModerationScope?: boolean;

  // Handle errors from our API server
  onError?: (error: Error) => void;
}

export interface AuthenticationRequestResponse {
  // Unique value for the OAuth2 state parameter generated by the server
  state: string;

  // Request token retrieved from the OAuth1 provider by the server
  requestToken: string;
}
