import { clsx } from 'clsx';
import { IconRoundLoader } from 'icons';
import React from 'react';
import { Link, LinkProps as RRLinkProps } from 'react-router-dom';

type Variant = 'inlineBlock' | 'rounded';

type Size = 'lg' | 'md' | 'sm' | 'xs';

type Color =
  | 'primary'
  | 'socialie'
  | 'grey'
  | 'medGrey'
  | 'white'
  | 'darkGrey'
  | 'outline'
  | 'outlineInverted'
  | 'error'
  | 'destructive'
  | 'warning'
  | 'success'
  | 'facebook'
  | 'twitter'
  | 'instagram'
  | 'instagram_graph'
  | 'instagram_basic'
  | 'linkedin';

interface BaseProps {
  color?: Color;
  size?: Size;
  variant?: Variant | Variant[];
  leftIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  rightIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  isLoading?: boolean;
}

export interface BtnButtonProps
  extends BaseProps,
    Omit<React.ComponentProps<'button'>, 'color'> {}

export interface BtnAProps
  extends BaseProps,
    Omit<React.ComponentProps<'a'>, 'color'> {}

export interface BtnLinkProps extends BaseProps, Omit<RRLinkProps, 'color'> {}

export type BtnProps = BtnButtonProps | BtnAProps | BtnLinkProps;

export default function Btn(props: BtnProps) {
  const {
    variant,
    size = 'lg',
    leftIcon,
    rightIcon,
    children,
    className,
    isLoading,
    ...restProps
  } = props;

  const color = props.color?.match('instagram')
    ? 'instagram'
    : props.color || 'primary';

  const baseClassNames = clsx(
    'relative rounded-lg w-full text-center p-2 transition-color duration-150 disabled:cursor-not-allowed disabled:opacity-50'
  );

  const bgColorClassName = clsx({
    'bg-socialiePink hover:bg-socialiePinkHover': color === 'socialie',
    'bg-primary hover:bg-primaryHover': color === 'primary',
    'bg-grey8 hover:bg-grey6': color === 'grey',
    'bg-grey6 hover:bg-grey5': color === 'medGrey',
    'bg-white': color === 'white' || color === 'outline' || color === 'error',
    'bg-grey2 hover:bg-black': color === 'darkGrey',
    'bg-error': color === 'destructive',
    'bg-warning': color === 'warning',
    'bg-facebook': color === 'facebook',
    'bg-twitter': color === 'twitter',
    'bg-instagram': color === 'instagram',
    'bg-linkedin': color === 'linkedin',
    'bg-teal': color === 'success',
    'hover:bg-grey9': color === 'outline',
  });

  const textColorClassName = clsx(
    color === 'error'
      ? 'text-error'
      : ['grey', 'white', 'outline', 'medGrey'].includes(color)
      ? 'text-dark'
      : 'text-white'
  );

  const fontSizeClassNames = clsx({
    'text-16 leading-24': size === 'md' || size === 'lg',
    'text-14 leading-20': size === 'sm' || size === 'xs',
  });

  const hasBorder =
    color === 'outline' || color === 'error' || color === 'outlineInverted';

  const sizeClassNames = clsx({
    'h-6': size === 'lg',
    'h-5': size === 'md',
    'h-4': size === 'sm',
    'h-3': size === 'xs',
  });

  const borderClassNames = clsx({
    'border-solid border-2': hasBorder,
    'border-darkText': color === 'outline',
    'border-error': color === 'error',
    'border-white': color === 'outlineInverted',
  });

  const variantClassNames = clsx(
    variant === 'rounded' ? 'rounded-full' : 'rounded-lg',
    variant === 'inlineBlock'
      ? 'inline-block w-auto flex-grow-0'
      : 'block w-full'
  );

  const loadingSpinnerColor =
    color === 'error'
      ? '#FF0000'
      : color === 'grey' || color === 'white' || color === 'outline'
      ? '#101820'
      : '#fff';

  const classNames = clsx(
    baseClassNames,
    bgColorClassName,
    textColorClassName,
    sizeClassNames,
    fontSizeClassNames,
    borderClassNames,
    variantClassNames,
    className
  );

  const innards = (
    <span className="flex items-center justify-center gap-1 text-center h-full">
      {leftIcon && React.createElement(leftIcon, { className: 'w-2.5 h-2.5' })}
      <span className="bump-up-1 font-semibold">{children}</span>
      {rightIcon &&
        React.createElement(rightIcon, { className: 'w-2.5 h-2.5' })}

      {isLoading && (
        <span
          className={clsx(
            'absolute inset-0 flex items-center justify-center rounded-lg',
            bgColorClassName
          )}
        >
          <IconRoundLoader className={clsx('w-2 h-2', loadingSpinnerColor)} />
        </span>
      )}
    </span>
  );

  return 'to' in restProps ? (
    <Link
      className={classNames}
      data-loading={isLoading}
      {...restProps}
      aria-busy={isLoading}
    >
      {innards}
    </Link>
  ) : 'href' in restProps ? (
    <a
      className={classNames}
      data-loading={isLoading}
      {...restProps}
      aria-busy={isLoading}
    >
      {innards}
    </a>
  ) : (
    <button
      className={classNames}
      data-loading={isLoading}
      disabled={isLoading || ('disabled' in restProps && restProps.disabled)}
      type="button"
      aria-busy={isLoading}
      {...(restProps as React.ComponentProps<'button'>)}
    >
      {innards}
    </button>
  );
}
