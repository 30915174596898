/**
 * Uses an XMLHttpRequest to upload a FilePart ... b/c the fetch api doesn't
 * support upload progress or canceling the upload
 */
import { FilePart } from './FilePart';
import { STATUS } from './S3MultipartUploader';

function getAwsResponse(xhr: XMLHttpRequest) {
  const code = elementText(xhr.responseText, 'Code');
  const msg = elementText(xhr.responseText, 'Message');
  return code.length ? `AWS Code: ${code}, Message: ${msg}` : '';
}

function elementText(source: string, element: string) {
  const match = source.match(`<${element}>(.+)</${element}>`);
  return match ? match[1] : '';
}

export class FilePartUploader {
  xhr: XMLHttpRequest = new XMLHttpRequest();
  resolve: (result: any) => void = () => {};
  reject: (reason: any) => void = () => {};
  bytesUploaded: number = 0;

  constructor(public filePart: FilePart) {}

  initialize() {
    this.bytesUploaded = 0;
    this.xhr = new XMLHttpRequest();
    this.xhr.onreadystatechange = () => this.onReadyStateChange();
    const { upload } = this.xhr;
    upload.onprogress = event => this.onProgress(event);
    upload.onerror = () => this.onError();
    upload.onabort = () => this.reject(STATUS.CANCELED);
  }

  upload(
    url: string,
    headers: { [header: string]: string },
    payload: any
  ): Promise<any> {
    this.initialize();
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
      const { xhr } = this;
      xhr.open('PUT', url);
      Object.keys(headers).forEach(key => {
        xhr.setRequestHeader(key, headers[key]);
      });
      xhr.send(payload);
    });
  }

  onReadyStateChange() {
    const { xhr } = this;
    if (
      xhr.readyState === XMLHttpRequest.DONE &&
      xhr.status >= 200 &&
      xhr.status <= 299
    ) {
      const header = this.xhr.getResponseHeader('ETag');
      this.resolve(header);
    }
  }

  onProgress(event: ProgressEvent) {
    const delta = event.loaded - this.bytesUploaded;
    this.bytesUploaded = event.loaded;
    this.filePart.uploader.updateBytesLoaded(delta);
  }

  onError() {
    const { xhr } = this;
    const reason = xhr.responseText ? getAwsResponse(xhr) : 'transport error';
    this.reject(reason);
  }
}
