import { schema, normalize } from 'normalizr';
import uniq from 'lodash/uniq';
import omit from 'lodash/omit';
import difference from 'lodash/difference';
import compact from 'lodash/compact';
import camelize from 'camelize';
import * as api from 'api/attachments';
import { OtherAction } from './global';
import { S3MultipartUploader } from 'helpers/upload/S3MultipartUploader';
import Attachment from 'types/Attachment';
import PlatformId from 'types/PlatformId';
import { RootState } from '.';
import { fileReady, processingError } from './fileUploads';

// ActionTypes
export enum TypeKeys {
  FETCHED = 'publisher/attachments/FETCHED',
  CREATED = 'publisher/attachments/CREATED',
  UPDATED = 'publisher/attachments/UPDATED',
  SAVED_MEDIA = 'platform/attachments/SAVED_MEDIA',
  DELETED = 'publisher/attachments/DELETED',
}

export interface CreatedAction {
  type: TypeKeys.CREATED;
  payload: {
    attachment: Attachment;
    requestKey: string;
  };
}

interface UpdatedAction {
  type: TypeKeys.UPDATED;
  payload: {
    attachment: Attachment;
  };
}

interface SavedMediaAction {
  type: TypeKeys.SAVED_MEDIA;
  payload: {
    attachmentId: number;
  };
}

interface FetchedAction {
  type: TypeKeys.FETCHED;
  payload: Attachment[];
}

export interface DeletedAction {
  type: TypeKeys.DELETED;
  payload: { ids: number[]; requestKey?: string };
}

type ActionTypes =
  | SavedMediaAction
  | FetchedAction
  | CreatedAction
  | UpdatedAction
  | DeletedAction
  | OtherAction;

// Schema
const attachmentSchema = new schema.Entity('attachments');

// Reducer
export interface State {
  byId: { [key: number]: Attachment };
  ids: number[];
}

const initialState: State = { byId: {}, ids: [] };

export default (state = initialState, action: ActionTypes) => {
  switch (action.type) {
    case TypeKeys.FETCHED: {
      const { entities, result } = normalize(action.payload, [
        attachmentSchema,
      ]);
      return {
        ...state,
        byId: { ...state.byId, ...entities.attachments },
        ids: uniq([...state.ids, ...result]),
      };
    }

    case TypeKeys.CREATED: {
      const { attachment } = action.payload;
      return {
        ...state,
        byId: { ...state.byId, [attachment.id]: attachment },
        ids: [...state.ids, attachment.id],
      };
    }

    case TypeKeys.UPDATED: {
      const { attachment } = action.payload;
      const updatedAttachment = {
        ...state.byId[attachment.id],
        ...attachment,
      };
      return {
        ...state,
        byId: { ...state.byId, [attachment.id]: updatedAttachment },
      };
    }

    case TypeKeys.SAVED_MEDIA: {
      const { byId } = state;
      const { attachmentId: id } = action.payload;
      return {
        ...state,
        byId: { ...byId, [id]: { ...state.byId[id], savedMedia: true } },
      };
    }

    case TypeKeys.DELETED: {
      const byId = omit(state.byId, action.payload.ids);
      const ids = difference(state.ids, action.payload.ids);
      return { ...state, byId, ids };
    }

    default:
      return state;
  }
};

// Action Creators
export const fetchedAttachments = (attachments: Attachment[]) => ({
  type: TypeKeys.FETCHED,
  payload: attachments,
});

export const updateAttachment = (attachment: Attachment) => ({
  type: TypeKeys.UPDATED,
  payload: { attachment },
});

const checkAttachmentStatus = (id: number, uploadId: string) => async (
  dispatch: Function,
  getState: () => RootState
) => {
  const attachment = getState().attachments.byId[id];

  // Attachment status might have already been updated by a socket message
  if (
    !attachment ||
    !['processing', 'unprocessed'].includes(attachment.status)
  ) {
    return;
  }

  const { status } = await api.checkStatus(attachment.id);
  if (status === 'processed') {
    dispatch(updateAttachment({ ...attachment, status }));
    dispatch(fileReady(uploadId, { ...attachment, status }));
  } else if (status === 'processing_failed') {
    dispatch(updateAttachment({ ...attachment, status }));
    dispatch(processingError(uploadId, ''));
  } else if (['processing', 'unprocessed'].includes(status)) {
    // try again in 5 seconds
    setTimeout(() => dispatch(checkAttachmentStatus(id, uploadId)), 5000);
  } else {
    console.log('Unhandled attachment status', attachment.status, attachment);
  }
};

export const createAttachment = (
  requestKey: string,
  uploader: S3MultipartUploader,
  socketId: string,
  uploadId: string,
  flipMedia?: boolean
) => async (dispatch: Function) => {
  if (uploader.status === 'canceled') {
    return;
  }
  const params = {
    requestKey,
    ...uploader.attachmentData(),
    socketId: `${socketId}:${uploadId}`,
    flipMedia,
  };

  const json = await api.createAttachment(params);
  const attachment: Attachment = camelize(json);
  dispatch({ type: TypeKeys.CREATED, payload: { requestKey, attachment } });

  // Sometimes the socket message gets missed, so after 10 seconds we can
  // fall back on some good old fashioned polling
  setTimeout(
    () => dispatch(checkAttachmentStatus(attachment.id, uploadId)),
    10000
  );
};

export const savedMedia = (
  requestKey: string,
  platformId: PlatformId,
  attachmentId: number
) => (dispatch: Function) => {
  api.createSavedMediaEvent(requestKey, attachmentId);

  dispatch({
    type: TypeKeys.SAVED_MEDIA,
    payload: {
      requestKey,
      platformId,
      attachmentId,
    },
  });
};

export const deleteAttachments = (
  ids: number[],
  publisherId: number,
  requestKey?: string
) => async (dispatch: Function) => {
  await Promise.all(ids.map((id) => api.deleteAttachment(id, publisherId)));
  dispatch({ type: TypeKeys.DELETED, payload: { ids, requestKey } });
};

// Selectors
export const getAttachmentsByIds = (
  state: { attachments: State },
  ids: number[]
) => {
  return compact(ids.map((id) => state.attachments.byId[id]));
};
