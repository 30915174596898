import * as Sentry from '@sentry/react';
import { get, post } from './shared';
import LoggedInPublisher from 'types/LoggedInPublisher';
import UserApiPayload from 'types/UserApiPayload';

export const fetchCurrentPublisher = async (publisherCode?: string) => {
  const incomingParams = new URL(window.location.href).searchParams;
  const authToken = incomingParams.get('auth_token');
  const outgoingParams = new URLSearchParams();
  if (publisherCode) outgoingParams.append('code', publisherCode);
  if (authToken) outgoingParams.append('auth_token', authToken);
  const url = `publisher/current?${outgoingParams.toString()}`;
  return get(url);
};

export const updateCurrentPublisher = async (
  id: number,
  publisher: Partial<LoggedInPublisher>,
  extraParams?: { [key: string]: any }
) => {
  const endpoint = `publisher/publishers/${id}`;
  return post(endpoint, { publisher, ...extraParams }, 'PUT');
};

export const createPublisher = async (name: string, inviteCode?: string) => {
  return post<UserApiPayload>('publisher/publishers', { name, inviteCode });
};

export const clearCurrentPublisher = async () =>
  post<UserApiPayload>('publisher/current/clear');

export const identifySentryUser = (publisher: LoggedInPublisher) => {
  if (process.env.NODE_ENV === 'development' || !publisher?.id) return;

  Sentry.setUser({
    id: publisher.id.toString(),
    displayName: publisher.name,
    accountName: publisher.accountName,
  });
};

/**
 * Server will follow the suggester's twitter dm account, safe to call multiple
 * times b/c the server checks to see if they're not linked/already following
 * @param {number} publisherId
 * @returns {Promise<Response>}
 */
export const followSuggester = async (publisherId: number) => {
  return post(`publisher/publishers/${publisherId}/follow_suggester`);
};

export const fetchPublisherCodeUrl = async (publisherId: number) => {
  return post<{ url: string; code: string; brand: string }>(
    'publisher/publisher_codes',
    {
      publisherId,
    }
  );
};

export const claimLegacyPublisher = async () => {
  return post<UserApiPayload>('publisher/publishers/claim-legacy-publisher');
};

export const copyPublisher = async (params: {
  publisherId: number;
  suggesterId: number;
  inviteCode: string;
}) =>
  post<UserApiPayload | { errors: string }>(
    'publisher/publishers/copy',
    params
  );
