export default {
  home: '/',

  fromWebRedirect: '/from-web',

  publisherCode: '/c/:code',

  feeds: {
    inbox: '/inbox',
    archived: '/archived',
    browse: '/inbox/:requestKey',
  },

  inbox: {
    root: '/inbox',
    suggestedContent: {
      index: '/inbox/suggested-content',
      suggestions: '/inbox/suggested-content/suggestions',
    },
    archived: '/inbox/archived',
    contentRequests: '/inbox/content-requests',
  },

  media: {
    root: '/media',
    favorites: '/media/favorites/:id',
    myAlbums: '/media/my-albums',
    myAlbum: '/media/my-albums/:id',
    sharedAlbum: '/media/shared-albums/:id',
    sharedAlbums: '/media/shared-albums',
    gettySearch: '/media/getty-images',
    uploads: '/media/uploads',
  },

  gettySearch: {
    index: '/getty-images',
  },

  uploads: {
    index: '/uploads',
  },

  invitation: {
    index: '/invitations',
    root: '/invitations/:code',
    secondaryContact: '/invitations/:code/secondary-contact',
    linkAccounts: '/invitations/:code/accounts',
    notifications: '/invitations/:code/notifications',
  },

  settings: {
    root: '/settings',
    brand: '/settings/brand',
    contactInfo: {
      index: '/settings/contact-info',
      twitter: '/settings/contact-info/twitter',
      manage: '/settings/contact-info/:protocol',
    },
    name: '/settings/name',
    security: {
      index: '/settings/security',
      changePassword: '/settings/security/change-password',
      manageMfa: '/settings/security/2fa',
      recoveryCodes: '/settings/security/recovery',
    },
    notifications: '/settings/notifications',
    users: {
      index: '/settings/users',
      invite: '/settings/users/invite',
      edit: '/settings/users/:id',
    },
    primaryMethod: '/settings/contact-info/primary-contact',
    identities: '/settings/social-accounts',
    pageSelector: '/settings/social-accounts/page-selector',
    igAccountSelector: '/settings/social-accounts/instagram-account-selector',
    publisherSwitcher: '/settings/publisher-switcher',

    // Delete these after getting rid of OldSettings
    socialAccounts: '/settings/social-accounts',
  },

  auth: {
    login: {
      root: '/sign-in',
      user: '/sign-in',
      social: '/sign-in/social',
    },
    facebookPageLogin: '/facebook-page-login',
    resetPassword: '/reset-password/:token',
    magicSignIn: '/magic-sign-in/:token',
  },

  mfa: {
    index: ':base/2fa',
    getStarted: ':base/2fa/get-started',
    chooseProtocol: ':base/2fa/choose-protocol',
    verifyPassword: ':base/2fa/verify-password',
    register: ':base/2fa/register',
    verifySms: ':base/2fa/verify-sms',
    verifyApp: ':base/2fa/verify-app',
    recoveryCodes: ':base/2fa/recovery-codes',
    finish: ':base/2fa/finish',
    disable: ':base/2fa/disable',
  },

  onboarding: {
    all: '/get-started/*',
    root: '/get-started',
    getStarted: '/get-started',
    message: '/get-started/motd',
    tour: '/get-started/tour',
    user: '/get-started/user-account',
    newUserSplash: '/get-started/sign-up-success',
    secondaryContact: '/get-started/secondary-contact',
    publisherList: '/get-started/publisher-list',
    linkAccounts: '/get-started/accounts',
    // optional route param to bypass the normal page you land on after linking
    pageSelector: '/get-started/accounts/facebook/page-selector',
    igAccountSelector: '/get-started/accounts/instagram/account-selector',
    notifications: '/get-started/notifications',
    complete: '/get-started/complete',
  },

  publisherRequests: {
    feedGuard: '/:requestKey/done',
    exampleEmbed: '/:requestKey/embed-example',
    show: '/:requestKey/:platform?/:modal?',
    igAccountSelector: '/:requestKey/:platform/account-selector',
    upgradeInstagram: '/:requestKey/:platform/upgrade-instagram',
  },

  contentRequests: {
    index: '/:requestKey/',
    uploading: '/:requestKey/uploads',
  },

  accountConversion: {
    root: '/upgrade-account',
    secondaryContact: '/upgrade-account/secondary-contact',
    notifications: '/upgrade-account/notifications',
    success: '/upgrade-account/success',
  },

  publisherSelect: '/publisher-select',

  athlete: {
    root: '/athlete',
    dashboard: '/athlete/dashboard',
    audience: '/athlete/audience/:platform?',
    audiencePageSelector: '/athlete/audience/facebook/page-selector',
    posts: '/athlete/posts/:platform?',
    postsPageSelector: '/athlete/posts/facebook/page-selector',
    postsDetail: '/athlete/posts/:platform/:id',
    leaderboards: '/athlete/leaderboards',
    leaderboardsPageSelector: '/athlete/leaderboards/page-selector',
    moderation: '/athlete/protection',
    moderationPageSelector: '/athlete/protection/page-selector',
    moderationIgAccountSelector:
      '/athlete/protection/instagram-account-selector',
    comments: '/athlete/comments/:platform?',
    identities: '/athlete/social-accounts',
  },
};
